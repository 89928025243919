import moment from "moment-mini";

const apiConfig = {
  baseUrl: "https://api.sportsdata.io/v3/nba/",
  apiKey: "7b6585b267164839af9e611465f2cdb7",
};

class Api {
  constructor() {
    this.baseUrl = apiConfig.baseUrl;
    this.apiKey = apiConfig.apiKey;
    this.today = moment().format("YYYY-MM-DD");
    this.teamsData = [];
    this.fetchAllTeams();
  }

  static getInstance() {
    if (!Api.instance) {
      Api.instance = new Api();
    }
    return Api.instance;
  }

  async get(path, params = { key: this.apiKey }) {
    const url = new URL(`${this.baseUrl}${path}`);

    Object.entries(params).forEach(([key, val]) => {
      url.searchParams.append(key, val);
    });

    const res = await fetch(url);
    const data = await res.json();

    if (!res.ok) {
      throw new Error(data.error);
    }

    return data;
  }

  async fetchAllTeams() {
    const path = "scores/json/AllTeams";
    return this.get(path).then((data) => {
      const teams = data.map((team) => {
        return {
          key: team.Key,
          logo: team.WikipediaLogoUrl,
          name: team.Name,
        };
      });
      this.teamsData = teams;
      return teams;
    });
  }

  async getAllTeams() {
    const path = "scores/json/AllTeams";
    return this.get(path);
  }

  async getSchedule(season) {
    const path = `scores/json/Games/${season || 2023}`;
    return this.get(path);
  }

  async getGamesByDate(date) {
    const path = `scores/json/GamesByDate/${date || this.today}`;
    return this.get(path);
  }

  async areAnyGamesInProgress() {
    const path = `scores/json/AreAnyGamesInProgress`;
    return this.get(path);
  }

  async getBoxScores(date) {
    const path = `stats/json/BoxScores/${date || this.today}`;
    return this.get(path);
  }

  async getBoxScoresDelta(date, minutes = 1) {
    const path = `stats/json/BoxScoresDelta/${date || this.today}/${minutes}`;
    return this.get(path);
  }

  async getNews(date) {
    const path = `scores/json/News`;
    return this.get(path);
  }

  get teams() {
    return this.teamsData;
  }
}

export default Api.getInstance();
