import { useContext, useEffect, useState } from "react";
import { SubmenuContext } from "../../../store/context/submenu";
import { Watch, Standings, Schedule, Playoffs, Stats } from "./submenus";
import { MlbTabs } from "../../../consts";

const MlbScreen = () => {
  const { submenu, setSubmenu } = useContext(SubmenuContext);
  const [displaySubmenu, setDisplaySubmenu] = useState(submenu);

  useEffect(() => {
    let screen;

    switch (submenu) {
      case MlbTabs.WATCH:
        screen = <Watch />;
        break;
      case MlbTabs.STANDINGS:
        screen = <Standings />;
        break;
      case MlbTabs.SCHEDULE:
        screen = <Schedule />;
        break;
      case MlbTabs.PLAYOFFS:
        screen = <Playoffs />;
        break;
      case MlbTabs.STATS:
        screen = <Stats />;
        break;
      default:
        return;
    }

    setDisplaySubmenu(screen);

    return () => setSubmenu("");
  }, [submenu, setSubmenu]);

  return displaySubmenu;
};

export default MlbScreen;
