const NavList = {
  NBA: "nba",
  MLB: "mlb",
  NFL: "nfl",
  SETTINGS: "settings",
};

const MlbTabs = {
  WATCH: "Watch",
  STANDINGS: "Standings",
  SCHEDULE: "Schedule",
  PLAYOFFS: "Playoffs",
  STATS: "Stats",
};

const WatchTabs = {
  SCOREBOARD: "scoreboard",
  LINEUPS: "lineups",
  GAMETRACKER: "game Tracker",
};

const gameStatus = {
  LIVE: "InProgress",
  UPCOMING: "Scheduled",
  FINAL: "Final",
};

const liveLikeStatus = {
  liveChat: "LIVE CHAT",
  interact: "INTERACT",
  leaderboard: "LEADERBOARD",
};

const mobileStatus = {
  liveChat: "LIVE CHAT",
  interact: "INTERACT",
  watchParty: "WATCH PARTY",
  leaderboard: "LEADERBOARD",
};

const interact = "interact";
const liveChat = "liveChat";
const watchParty = "watchParty";
const leaderBoard = "leaderboard";

const messages = {
  [gameStatus.LIVE]: "There are no live games at this moment.",
  [gameStatus.UPCOMING]: "There are no scheduled on that day.",
  [gameStatus.FINAL]: "There are no final games results for this day.",
};

const THEME = "dark";

const placeholderGame = {
  awayTeamUid: 3636,
  homeTeamUid: 3634,
  matchId: 38066609,
  matchStatus: "post",
  realCategoryId: 16,
  seasonId: 100127,
  sportId: 3,
  tournamentId: 25,
  uniqueTournamentId: 109,
  usSport: 3,
};

const gamesSchedule = "GAMES SCHEDULE";

const largeMobileSize = 710;

const dynamicVideoLink = "http://13.53.194.134:1337/video-playlists/3";

const samplePlaylists = {
  nba: ["https://cs1.brightcodes.net/crutman/NBA_Stream/playlist_dvr.m3u8"],
  mlb: ["https://cs1.brightcodes.net/crutman/MLB_Stream/playlist_dvr.m3u8"],
  // "https://dvj3c35tfmke1.cloudfront.net/out/v1/85b046dfa5c1467e9dfa59abb2344c9d/aaa180f8221b40bd997c7928df70f597/955c3e517be54f0188c31df99d76edeb/index.m3u8",
  // "https://dvj3c35tfmke1.cloudfront.net/out/v1/6cf214ec341745cf8943f7764074a904/aaa180f8221b40bd997c7928df70f597/955c3e517be54f0188c31df99d76edeb/index.m3u8",
};

// Agora videoCall.js configurations
const appId = "20b7c51ff4c644ab80cf5a4e646b0537";
const token = null;
const channelName = "Accedo";

const agoraConfig = {
  mode: "rtc",
  codec: "vp8",
};

const ClientEvents = {
  USER_PUBLISHED: "user-published",
  USER_UNPUBLISHED: "user-unpublished",
  USER_LEFT: "user-left",
};

const MediaTypes = {
  VIDEO: "video",
  AUDIO: "audio",
};

const livelikeConfig = {
  [NavList.NBA]: {
    programId: "3dd9c2f9-4f26-448c-9555-416e9d9ea24f",
    widgets: [
      {
        syncTime: 5,
        id: "2b3cad43-1428-444b-9618-eed0d1432074",
        kind: "image-poll",
      },
      {
        syncTime: 60,
        id: "ff4dd01d-0954-4dcc-8517-c9ba5feac0cc",
        kind: "text-quiz",
      },
      {
        syncTime: 150,
        id: "63af3cc4-d326-4ebd-937d-aa9d7f99ff4d",
        kind: "cheer-meter",
      },
      {
        syncTime: 90,
        id: "29ede257-8747-42d5-87e7-53f936bf31a8",
        kind: "text-poll",
      },
      {
        syncTime: 240,
        id: "a3b89b7a-6cc3-47e6-98c9-306534582939",
        kind: "emoji-slider",
      },
      {
        syncTime: 30,
        id: "953ccbb9-4308-4aa2-88b3-3b67b8b7fb56",
        kind: "image-poll",
      },
      {
        syncTime: 120,
        id: "bc4fb7e4-5dd7-4ff6-8f8d-db3b40d3a8a3",
        kind: "text-quiz",
      },
      {
        syncTime: 210,
        id: "a1896b74-87d8-4b5e-b14f-7077a320012b",
        kind: "cheer-meter",
      },
      {
        syncTime: 180,
        id: "99a05777-d778-4f79-a83e-084b17eba727",
        kind: "image-poll",
      },
      {
        syncTime: 370,
        id: "d38da948-05c0-4060-9e55-33dbff5076b8",
        kind: "emoji-slider",
      },
      {
        syncTime: 270,
        id: "1cdef2bb-a77a-41cc-9798-f3748b22c366",
        kind: "text-poll",
      },
      {
        syncTime: 310,
        id: "2e8983b9-f0b1-480a-87ea-cbb155e0ad7d",
        kind: "image-poll",
      },
      {
        syncTime: 400,
        id: "a0f5640c-144e-4348-9892-8010f3b8e3d0",
        kind: "text-poll",
      },
    ],
  },
  [NavList.MLB]: {
    programId: "994d9eb7-c258-49a0-86ac-bd0dd7143a78",
    widgets: [],
  },
};

export {
  NavList,
  MlbTabs,
  WatchTabs,
  gameStatus,
  gamesSchedule,
  messages,
  mobileStatus,
  interact,
  liveChat,
  watchParty,
  leaderBoard,
  largeMobileSize,
  liveLikeStatus,
  dynamicVideoLink,
  samplePlaylists,
  placeholderGame,
  THEME,
  appId,
  token,
  channelName,
  agoraConfig,
  ClientEvents,
  MediaTypes,
  livelikeConfig,
};
