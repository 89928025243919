import { useContext } from "react";
import NavbarItem from "./navbarItem";
import { NavList, THEME } from "../../consts";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ScheduleWidget, SportsRadarWidget } from "../widgets";
import { TabContext } from "../../store/context/tab";
import "./navbar.scss";
import { SportContext } from "../../store/context/sport";

const NavBar = () => {
  const daysShownMlbSchedule = 100000;
  const { tab } = useContext(TabContext);
  const { setSportInfo } = useContext(SportContext);
  const navBarTabs = Object.values(NavList);

  function onItemClicked(type, obj) {
    if (type !== "match") {
      return;
    }

    setSportInfo(obj);
  }

  return (
    <nav>
      <div className="navbar-container">
        <div className="logo">
          <Logo />
        </div>
        <div className="nav-elements">
          <ul>
            {navBarTabs.map((text) => (
              <NavbarItem title={text} />
            ))}
          </ul>
        </div>
      </div>
      {(tab === NavList.NBA && (
        <div className="subnav-container--nba">
          <ScheduleWidget />
          <div className="sub-nav-visuallyHidden" />
        </div>
      )) ||
        (tab === NavList.MLB && (
          <div className="subnav-container--mlb">
            <SportsRadarWidget
              id="score-ticker"
              widget="us.common.scoreTicker"
              params={{
                theme: THEME,
                sport: tab,
                allowedSports: tab,
                prevDays: daysShownMlbSchedule,
                nextDays: daysShownMlbSchedule,
                onItemClick: onItemClicked,
              }}
            />
          </div>
        ))}
    </nav>
  );
};

export default NavBar;
