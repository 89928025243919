import { useState, useEffect, useRef, useCallback } from "react";

import api from "../api/api";
import { gameStatus } from "../consts";

const useLiveBoxScore = (interval = 5000) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const intervalId = useRef(null);

  const doFetch = useCallback(async () => {
    try {
      const areAnyGamesInProgress = await api.areAnyGamesInProgress();

      if (!areAnyGamesInProgress) {
        setData(null);
        return;
      }

      const boxScores = await api.getBoxScoresDelta();

      const finalData = boxScores
        .filter((item) => item.Game.Status === gameStatus.LIVE)
        .map((boxScore) => {
          const homeTeam = api.teams.find(
            (team) => team.key === boxScore.Game.HomeTeam
          );
          const awayTeam = api.teams.find(
            (team) => team.key === boxScore.Game.AwayTeam
          );
          const { logo: homeTeamLogo, name: homeTeamName } = homeTeam;
          const { logo: awayTeamLogo, name: awayTeamName } = awayTeam;

          return {
            gameStatus: boxScore.Game.Quarter,
            remainingMinutess: boxScore.Game.TimeRemainingMinutes,
            remainingSecondss: boxScore.Game.TimeRemainingSeconds,
            homeTeamScore: boxScore.Game.HomeTeamScore,
            awayTeamScore: boxScore.Game.AwayTeamScore,
            homeTeamLogo,
            homeTeamName,
            awayTeamLogo,
            awayTeamName,
          };
        });
      setData(finalData);
    } catch (err) {
      setData(null);
      setError(err);
    }
  }, []);

  useEffect(() => {
    doFetch();

    if (interval) {
      intervalId.current = setInterval(() => {
        doFetch();
      }, interval);
    }
    return () => clearInterval(intervalId.current);
  }, [interval, doFetch]);

  return {
    data,
    error,
  };
};

export default useLiveBoxScore;
