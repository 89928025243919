import { gameStatus } from "../../../consts";
import { padLeft } from "../../../utils";
import "./scoreCardWidget.scss";

const ScoreCardWidget = ({ game, status }) => {
  const isLive = status === gameStatus.LIVE;
  const isPending = status === gameStatus.UPCOMING;
  const isFinal = status === gameStatus.FINAL;

  return (
    <div className="score-card">
      <div className="grid-item logo">
        <img src={game.homeTeamLogo} alt="home-logo" />
        <div className="team-name">{game.homeTeamName}</div>
      </div>
      <div className="grid-item stat">
        <>
          {isFinal || isPending ? (
            <div className="status">
              {isPending ? game.date : game.status.toUpperCase()}
            </div>
          ) : null}
          <div className="game-stats">
            <>
              {isLive && (
                <div className="quarter">
                  {game?.gameStatus &&
                    (!isNaN(game.gameStatus)
                      ? `Q${game.gameStatus}`
                      : game.gameStatus.toUpperCase())}
                </div>
              )}
              {isLive || isFinal ? (
                <div className="score">
                  <div>{game.homeTeamScore}</div>
                  <div>{game.awayTeamScore}</div>
                </div>
              ) : null}
            </>
            {isLive && (
              <div className="remaining-time">
                <div>
                  {padLeft(game.remainingMinutess, game.remainingSecondss)}
                </div>
              </div>
            )}
          </div>
          {isPending && <div>{game.time}</div>}
        </>
      </div>
      <div className="grid-item logo">
        <img src={game.awayTeamLogo} alt="away-logo" />
        <div className="team-name">{game.awayTeamName}</div>
      </div>
    </div>
  );
};

export default ScoreCardWidget;
