import { useContext, useEffect, useState } from "react";
import { MlbTabs, NavList } from "../../consts";
import { SubmenuContext } from "../../store/context/submenu";
import { TabContext } from "../../store/context/tab";
import "./navbarItem.scss";

const NavbarItem = ({ title }) => {
  const { tab, setTab } = useContext(TabContext);
  const { setSubmenu } = useContext(SubmenuContext);
  const [isActive, setIsActive] = useState(title === tab);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const dropDownSubmenus = Object.values(MlbTabs);

  function handleTabClick() {
    if (title === NavList.MLB && !isDropDownOpen) {
      setIsDropDownOpen(true);
    } else if (title === NavList.MLB && isDropDownOpen) {
      setIsDropDownOpen(false);
    } else {
      setTab(title);
    }
  }

  function handleSubmenuClick(name) {
    setTab(title);
    setSubmenu(name);
    setIsDropDownOpen(false);
  }

  useEffect(() => {
    setIsActive(tab === title);
  }, [tab, title]);

  return (
    <div key={title} onClick={handleTabClick}>
      <div className={"navbar-item" + (isActive ? "--active" : "")}>
        {title}
      </div>
      {isDropDownOpen && (
        <ul className="drop-down-menu">
          {dropDownSubmenus.map((name) => (
            <li key={name} onClick={() => handleSubmenuClick(name)}>
              {name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavbarItem;
