import { useEffect } from "react";

const SportsRadarWidget = ({ id, widget, params = {} }) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://widgets.media.sportradar.com/accedo/widgetloader";
    script.async = true;
    script.setAttribute("n", "SIR");
    document.head.appendChild(script);

    script.onload = () => {
      try {
        window.SIR("addWidget", "#" + id, widget, params);
      } catch (e) {
        console.error("Error loading widget: " + widget);
        console.error(e);
      }
    };

    return () => {
      document.head.removeChild(script);
    };
  }, [id, widget, params]);

  return <div id={id} />;
};

export default SportsRadarWidget;
