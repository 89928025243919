import { useContext, useState } from "react";
import { THEME, WatchTabs } from "../../../../../consts";
import { SportContext } from "../../../../../store/context/sport";
import VideoContainer from "../../../../videoContainer/videoContainer";
import { SportsRadarWidget } from "../../../../widgets";
import "./watch.scss";

const Watch = () => {
  const { sportInfo } = useContext(SportContext);
  const [activeTab, setActiveTab] = useState(WatchTabs.SCOREBOARD);
  const watchSubmenuWidgets = Object.values(WatchTabs);

  function textWithoutSpaces(text) {
    return text.replace(/\s/g, "");
  }

  function handleTabClick(tabName) {
    const tabNameWithoutSpaces = textWithoutSpaces(tabName);
    setActiveTab(tabNameWithoutSpaces);
  }

  return (
    <div className="mlb-watch--container">
      <VideoContainer />

      <div className="mlb-watch--selector">
        {watchSubmenuWidgets.map((text) => (
          <span
            className={
              "mlb-watch--selector-item" +
              (activeTab === textWithoutSpaces(text) ? "-active" : "")
            }
            onClick={() => handleTabClick(text)}
          >
            {text}
          </span>
        ))}
      </div>

      <div className="mlb-watch--widget">
        <div className="mlb-watch--widget-container">
          <SportsRadarWidget
            id={"mlb-watch-" + activeTab}
            widget={"us.match.mlb." + activeTab}
            params={{ ...sportInfo, theme: THEME }}
          />
        </div>
      </div>
    </div>
  );
};

export default Watch;
