import { useEffect, useState } from "react";
import { liveLikeStatus, liveChat, interact, leaderBoard } from "../../consts";
import LeaderBoard from "../leaderboard/leaderboard";
import LiveChat from "../liveChat/liveChat";
import Interact from "../interact/interact";
import livelike from "./sdk";
import "./livelike.scss";

const Tab = ({ label, activeTab, onClick }) => {
  return (
    <li
      key={label}
      className={`tab-item ${activeTab === label ? "active" : ""}`}
      onClick={() => onClick(label)}
    >
      <span>{liveLikeStatus[label]}</span>
    </li>
  );
};

const LiveLike = ({ program }) => {
  const [activeTab, setActiveTab] = useState(liveChat);
  const [chatRoom, setChatRoom] = useState({});
  const [leaderboardEntries, setLeaderboardEntries] = useState([]);

  useEffect(() => {
    livelike.init();
  }, []);

  useEffect(() => {
    livelike.setProgramId(program);
  }, [program]);

  useEffect(() => {
    switch (activeTab) {
      case liveChat:
        livelike.getDefaultChatRoom().then((chatRoom) => setChatRoom(chatRoom));
        break;
      case leaderBoard:
        livelike.getLeaderboardEntries().then((entries) => {
          setLeaderboardEntries(entries);
        });
        break;
      case interact:
        livelike.handleWidgetLoop();
        break;
      default:
        return;
    }
  }, [activeTab]);

  const handleClick = (label) => {
    setActiveTab(label);
  };

  return (
    <div className="live-like-tabs">
      <div>
        <ul>
          {Object.keys(liveLikeStatus).map((liveLike) => (
            <Tab label={liveLike} activeTab={activeTab} onClick={handleClick} />
          ))}
        </ul>
        <div className="live-like-floater">
          {activeTab === liveChat && <LiveChat roomId={chatRoom.id} />}
          {
            <div className={`${activeTab === interact ? "" : "hide"}`}>
              <Interact programid={livelike.programId} />
            </div>
          }
          {activeTab === leaderBoard && (
            <LeaderBoard entries={leaderboardEntries} />
          )}
        </div>
      </div>
    </div>
  );
};
export default LiveLike;
