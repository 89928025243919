import { createContext, useState } from "react";
import { NavList } from "../../consts";

const initTab = NavList.NBA;

const TabContext = createContext({
  tab: initTab,
  setTab: () => {},
});

function TabContextProvider({ children }) {
  const [tab, setTab] = useState(initTab);

  function changeTab(newTab) {
    const isTab = Object.values(NavList).includes(newTab);

    if (tab === newTab || !isTab) {
      return;
    }

    setTab(newTab);
  }

  const contextValue = {
    tab: tab,
    setTab: changeTab,
  };

  return (
    <TabContext.Provider value={contextValue}>{children}</TabContext.Provider>
  );
}

export { TabContext, TabContextProvider };
