import { createContext, useEffect, useState } from "react";
import { placeholderGame } from "../../consts";

const SportContext = createContext({
  sportInfo: placeholderGame,
  setSportInfo: () => {},
});

function SportContextProvider({ children }) {
  const [sportInfo, setSportInfo] = useState(placeholderGame);

  // This could be updated in a better way by using the info API
  // from https://developer.sportradar.com/io-docs
  useEffect(() => {
    const formattedDate = new Date().toISOString().slice(0, 10);
    setSportInfo((previousInfo) => ({
      ...previousInfo,
      currentDate: formattedDate,
    }));
  }, []);

  const contextValue = {
    sportInfo: sportInfo,
    setSportInfo: setSportInfo,
  };

  return (
    <SportContext.Provider value={contextValue}>
      {children}
    </SportContext.Provider>
  );
}

export { SportContext, SportContextProvider };
