import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Hls from "hls.js";
import "./videoAsset.scss";
import { dynamicVideoLink, samplePlaylists } from "../../consts";
import { isiOSSafari } from "../../utils";
import { HlsJsSyncStrategy } from "@livelike/engagementsdk";
import { TabContext } from "../../store/context/tab";

const VideoAsset = () => {
  const { tab } = useContext(TabContext);
  const [videoList, setVideoList] = useState([]);
  const [currentIdx, setCurrentIdx] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoList && videoList.length > 0) {
      const hls = new Hls();
      const video = videoRef.current;

      const syncStrategy = new HlsJsSyncStrategy(hls, video);
      const vidTime = setInterval(() => {
        console.log({
          curr: syncStrategy.videoCurrentTimeMs,
          pdt: syncStrategy.programDateTime,
        });
      }, 5000);

      const url = videoList[currentIdx];
      if (Hls.isSupported()) {
        hls.loadSource(url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          video.play();
        });
      } else {
        // fallback for ios/safari
        video.setAttribute("src", url);
        video.setAttribute("playsinline", true);
      }
      return () => {
        hls.destroy();
        syncStrategy.hls.destroy();
        clearInterval(vidTime);
      };
    }
  }, [videoList, currentIdx]);

  const playVideo = useCallback(() => {
    const videoPlayer = document.getElementById("welcome-background-video");
    let nextIdx = currentIdx + 1;
    if (nextIdx < videoList.length) {
      videoPlayer.src = videoList[nextIdx];
      videoPlayer.play();
    } else {
      nextIdx = 0;
      videoPlayer.src = videoList[nextIdx];
      videoPlayer.play();
    }
    setCurrentIdx(nextIdx);
  }, [currentIdx, videoList]);

  useEffect(() => {
    const videoPlayer = document.getElementById("welcome-background-video");
    videoPlayer.addEventListener("ended", playVideo);
    return () => {
      videoPlayer.removeEventListener("ended", playVideo);
    };
  }, [playVideo]);

  useEffect(() => {
    setTimeout(() => {
      setVideoList(samplePlaylists[tab]);
    }, 500);

    // dynamicVideoLink uses http at the moment
    // const doFetch = async () => {
    //   await fetch(dynamicVideoLink)
    //     .then((res) => res.json())
    //     .then((res) => {
    //       setVideoList(res?.videos?.map((v) => v?.content?.HLS));
    //     })
    //     .catch((err) => console.warn(err));
    // };
    // doFetch();
  }, [tab]);

  return (
    <video
      ref={videoRef}
      controls
      autoPlay
      muted={!!isiOSSafari()}
      id="welcome-background-video"
    />
  );
};
export default VideoAsset;
