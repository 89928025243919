import { useContext } from "react";
import { THEME } from "../../../../../consts";
import { SportContext } from "../../../../../store/context/sport";
import { SportsRadarWidget } from "../../../../widgets";
import "./standings.scss";

const Standings = () => {
  const { sportInfo } = useContext(SportContext);

  return (
    <div className="mlb-standings--container">
      <div className="mlb-standings--widget">
        <SportsRadarWidget
          id="mlb-standings"
          widget="us.season.mlb.standings"
          params={{
            theme: THEME,
            seasonId: sportInfo?.seasonId,
          }}
        />
      </div>
    </div>
  );
};

export default Standings;
