import { createContext, useState } from "react";
import { MlbTabs } from "../../consts";

const initSubmenu = MlbTabs.WATCH;

const SubmenuContext = createContext({
  submenu: initSubmenu,
  setSubmenu: () => {},
});

function SubmenuContextProvider({ children }) {
  const [submenu, setSubmenu] = useState(initSubmenu);

  const contextValue = {
    submenu: submenu,
    setSubmenu: setSubmenu,
  };

  return (
    <SubmenuContext.Provider value={contextValue}>
      {children}
    </SubmenuContext.Provider>
  );
}

export { SubmenuContext, SubmenuContextProvider };
