import { useContext } from "react";
import { THEME } from "../../../../../consts";
import { SportContext } from "../../../../../store/context/sport";
import { SportsRadarWidget } from "../../../../widgets";
import "./schedule.scss";

const Schedule = () => {
  const { sportInfo } = useContext(SportContext);

  return (
    <div className="mlb-schedule--container">
      <div className="mlb-schedule--widget">
        <SportsRadarWidget
          id="mlb-schedule"
          widget="us.season.scores"
          params={{
            theme: THEME,
            selectedDate: sportInfo?.currentDate,
            seasonId: sportInfo?.seasonId,
            numFutureDays: 0,
          }}
        />
      </div>
    </div>
  );
};

export default Schedule;
