import { TabContextProvider } from "./tab";
import { SubmenuContextProvider } from "./submenu";
import { SportContextProvider } from "./sport";

function GlobalContext({ children }) {
  return (
    <TabContextProvider>
      <SportContextProvider>
        <SubmenuContextProvider>{children}</SubmenuContextProvider>
      </SportContextProvider>
    </TabContextProvider>
  );
}

export default GlobalContext;
