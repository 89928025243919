import { useState } from "react";
import { UseResizeListener } from "../../hooks";
import HeaderVideo from "../headerVideo/headerVideo";
import MobileTabs from "../mobileTabs/mobileTabs";
import VideoAsset from "../videoAsset/videoAsset";
import VideoCall from "../videoCall/videoCall";
import { watchParty } from "../../consts";
import "./videoContainer.scss";

const VideoContainer = () => {
  const { isMobile } = UseResizeListener();
  const [activeTab, setActiveTab] = useState(watchParty);

  return (
    <div className="video-container">
      {!isMobile && <HeaderVideo />}
      <VideoAsset />
      {isMobile && (
        <MobileTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      )}
      {activeTab === watchParty && <VideoCall />}
    </div>
  );
};

export default VideoContainer;
