import moment from "moment-mini";
import { useEffect, useRef, useState } from "react";

import { gameStatus, gamesSchedule, messages } from "../../../consts";
import { useBoxScore, useLiveBoxScore, usePrevious } from "../../../hooks";
import { ScoreCardWidget } from "../../widgets";
import "./scheduleWidget.scss";

const ScheduleWidget = ({ status }) => {
  const today = useRef(moment().format("YYYY-MM-DD"));
  const prevStatus = usePrevious(status);
  const [date, setDate] = useState(today.current);

  const { data: games } = useBoxScore(status, date);
  const { data: liveGames } = useLiveBoxScore();

  const gameData = status === gameStatus.LIVE ? liveGames : games;

  useEffect(() => {
    if (prevStatus !== status) {
      setDate(today.current);
    }
  }, [prevStatus, status]);

  return (
    <div className="wrapper">
      {status !== gameStatus.LIVE && (
        <div className="date-filter">
          <div
            className="chevron left"
            onClick={() => {
              if (status === gameStatus.UPCOMING && date === today.current) {
                return;
              }
              setDate((prev) =>
                moment(prev).subtract(1, "d").format("YYYY-MM-DD")
              );
            }}
          />
          <div>{moment(date).format("dddd, MMMM Do ")}</div>
          <div
            className="chevron right"
            onClick={() => {
              if (status === gameStatus.FINAL && date === today.current) {
                return;
              }
              setDate((prev) => moment(prev).add(1, "d").format("YYYY-MM-DD"));
            }}
          ></div>
        </div>
      )}
      {gameData?.length ? (
        gameData.map((game) => <ScoreCardWidget game={game} status={status} />)
      ) : (
        <div className="status-message">{messages[status]}</div>
      )}
    </div>
  );
};

const ScheduleWidgetContainer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const tabs = Object.entries(gameStatus).map(([label, status]) => {
    return {
      label,
      component: <ScheduleWidget status={status} />,
    };
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    return () => {
      setActiveTab(0);
    };
  }, [isOpen]);

  return (
    <div className={`schedule-widget ${isOpen ? "open" : ""}`}>
      <div
        className="widget-menu"
        onClick={() => (isOpen ? true : setIsOpen(!isOpen))}
      >
        {isOpen ? (
          <ul className="widget-tabs">
            {tabs.map((tab, i) => (
              <li
                key={`tab-${i}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveTab(i);
                }}
                className={activeTab === i ? "active" : ""}
              >
                {tab.label}
              </li>
            ))}
          </ul>
        ) : (
          <div className="schedule-title">{gamesSchedule}</div>
        )}
        <div
          className={`chevron ${isOpen ? "up" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen && (
        <div className="widget-content">{tabs[activeTab].component}</div>
      )}
    </div>
  );
};

export default ScheduleWidgetContainer;
