import { useState } from "react";
import "./controls.scss";
import { ReactComponent as Mute } from "../../assets/images/mute.svg";
import { ReactComponent as UnMute } from "../../assets/images/unmute.svg";
import { ReactComponent as Video } from "../../assets/images/video.svg";
import { ReactComponent as UnVideo } from "../../assets/images/unvideo.svg";
import { ReactComponent as EndCall } from "../../assets/images/end-call-icon.svg";

const Controls = ({ client, tracks, setStart }) => {
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
  };

  return (
    <div className="controls">
      <p className={trackState.audio ? "on" : ""} onClick={() => mute("audio")}>
        {trackState.audio ? <UnMute /> : <Mute />}
      </p>
      <p className={trackState.video ? "on" : ""} onClick={() => mute("video")}>
        {trackState.video ? <Video /> : <UnVideo />}
      </p>
      {
        <p onClick={() => leaveChannel()}>
          <EndCall />
        </p>
      }
    </div>
  );
};
export default Controls;
