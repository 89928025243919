import { THEME } from "../../../../../consts";
import { SportsRadarWidget } from "../../../../widgets";
import "./playoffs.scss";

const Playoffs = () => {
  return (
    <div className="mlb-playoffs--container">
      <div className="mlb-playoffs--widget">
        <SportsRadarWidget
          id="mlb-playoffs"
          widget="us.season.mlb.playoffs"
          params={{ theme: THEME }}
        />
      </div>
    </div>
  );
};

export default Playoffs;
