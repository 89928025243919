import LiveLike from "@livelike/engagementsdk";
import { livelikeConfig, NavList } from "../../consts";

class LiveLikeHelper {
  constructor(clientId, programId) {
    this.isInit = false;
    this.clientId = clientId || "D2S7AGXlP3KAT77wm9JXVqE9IVnjf00ga00oXmKd";
    this.programId = programId || livelikeConfig[NavList.NBA].programId;
    this.leaderboardId = "032898e8-9e24-422f-bbfe-b34326a6f181";
    this.fetchOpts = {
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
    };
  }

  init() {
    if (this.isInit) {
      return;
    }

    this.isInit = true;
    LiveLike.init({ clientId: this.clientId }).then((profile) => {
      console.log(`Connected to LiveLike with : ${JSON.stringify(profile.id)}`);
      this.handleWidgetLoop();
    });
  }

  setProgramId(program) {
    this.programId = livelikeConfig[program].programId;
  }

  request(url, opts) {
    return fetch(url, {
      ...this.fetchOpts,
      opts,
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => console.error(err));
  }

  static getInstance() {
    if (!LiveLikeHelper.instance) {
      LiveLikeHelper.instance = new LiveLikeHelper();
    }
    return LiveLikeHelper.instance;
  }

  deleteChatRoomMessage(chatRoomId) {
    let url = `https://cf-blast.livelikecdn.com/api/v1/chat-rooms/${chatRoomId}/delete-chat-message/`;
    return this.request(url, { method: "POST" });
  }

  getProgram() {
    let url = `https://cf-blast.livelikecdn.com/api/v1/programs/${this.programId}/`;
    return this.request(url, { method: "GET" });
  }

  async getDefaultChatRoom() {
    const program = await this.getProgram();
    const [defaultChatRoom] = program?.chat_rooms;
    return defaultChatRoom || {};
  }

  async getLeaderboardEntries() {
    try {
      const leaderBoardEntries = await LiveLike.getLeaderboardEntries({
        leaderboardId: this.leaderboardId,
      });
      const entries = leaderBoardEntries?.entries?.map(
        ({ rank, score, profile_nickname }) => {
          return { rank, score, profile_nickname };
        }
      );
      return entries;
    } catch (err) {
      console.log(err);
    }
  }

  async getProgramWidgets() {
    const getRandomInt = (max) => Math.floor(Math.random() * max);
    const nbaConfig = livelikeConfig[NavList.NBA];
    const response = await LiveLike.getPostedWidgets({
      programId: this.programId,
    });

    const widgets = response?.widgets?.map((widget, index) => {
      const syncTime = nbaConfig.widgets[index]?.syncTime ?? getRandomInt(800);

      return {
        id: widget?.id,
        kind: widget?.kind,
        syncTime: syncTime,
      };
    });

    return widgets || nbaConfig.widgets;
  }

  async handleWidgetLoop() {
    try {
      const livelikeWidgets = document.querySelector("livelike-widgets");

      // Custom mode to define what the widgets look like/act like when displaying
      LiveLike.registerWidgetMode("custom-mode", ({ widget }) => {
        const isWidgetQuiz =
          widget.kind === "text-quiz" || widget.kind === "image-quiz";

        // Remove the widget's dismiss button
        widget.hide_dismiss_button = true;

        // If the widget is not a quiz, immediately and
        //indefinitely display the vote percentages
        if (!isWidgetQuiz) {
          widget.hideVoteInResults = false;
          widget.defaultVoteHide = false;
          widget.changed = true;
          widget.interacted = true;
        }

        return livelikeWidgets.attach(widget).then(() => {
          // Make widgets interactive indefinitely
          widget.interactive({ timeout: null });

          if (!isWidgetQuiz) {
            widget.phase = "custom";
          }

          // Once a user selects a quiz answer,
          // the vote is created and the results are shown
          document.addEventListener("answer", (e) => e.target.results());
        });
      });

      livelikeWidgets.mode = "custom-mode";

      // Time to base future time calc on
      const zeroTime = new Date().getTime();

      // List of widgets that exist in the program
      const widgetArr = await this.getProgramWidgets();

      // When the widget time matches, create this widget element and display it
      const displayLoopedWidget = (widget) => {
        const found = Array.from(livelikeWidgets.children).find(
          (el) => el.widgetPayload.id === widget.id
        );

        // If the widget doesn't already exist in the list, display it.
        // Just a double check to be sure there is no duplication
        if (!found) {
          livelikeWidgets.createWidgetElement({
            id: widget.id,
            kind: widget.kind,
          });
        }
      };

      function loop(arr) {
        if (arr.length) {
          function checkNext(next) {
            // If the widget array item sync time is greater than or equal to
            // the current time sync diff, display the widget and start the loop again
            const syncTime = next.syncTime * 1000;
            const timeDiff = new Date().getTime() - zeroTime;

            if (timeDiff > syncTime) {
              displayLoopedWidget(nextItem);
              loop(arr);
            } else {
              setTimeout(() => checkNext(next), 1000);
            }
          }

          // Get next widget array item
          const nextItem = arr.shift();
          checkNext(nextItem);
        }
      }

      loop(widgetArr);
    } catch (err) {
      console.error("Loop handling failed\n\n", err);
    }
  }
}

export default LiveLikeHelper.getInstance();
