import VideoContainer from "../../videoContainer/videoContainer";
import "./nbaScreen.scss";

const NbaScreen = () => {
  return (
    <div className="nba-screen--container">
      <VideoContainer />
    </div>
  );
};

export default NbaScreen;
