export const padLeft = (min, sec) => {
  return min || sec
    ? `${String(min).padStart(2, "0")}:${String(sec).padStart(2, "0")}`
    : null;
};

export const isiOSSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
  const webkit = ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};
