import { useState, useEffect, useCallback } from "react";
import moment from "moment-mini";

import api from "../api/api";

const useBoxScore = (status, date) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const doFetch = useCallback(async () => {
    try {
      const boxScores = await api.getBoxScores(date);

      const finalData = boxScores
        .filter((item) => item.Game.Status === status)
        .map((boxScore) => {
          const homeTeam = api.teams.find(
            (team) => team.key === boxScore.Game.HomeTeam
          );
          const awayTeam = api.teams.find(
            (team) => team.key === boxScore.Game.AwayTeam
          );
          const { logo: homeTeamLogo, name: homeTeamName } = homeTeam;
          const { logo: awayTeamLogo, name: awayTeamName } = awayTeam;

          return {
            date: moment(boxScore.Game.DateTimeUTC)
              .format("MMM DD")
              .toUpperCase(),
            time: moment(boxScore.Game.DateTimeUTC, ["HH:mm"])
              .format("hh:mm a")
              .toUpperCase(),
            status: boxScore.Game.Status,
            homeTeamScore: boxScore.Game.HomeTeamScore,
            awayTeamScore: boxScore.Game.AwayTeamScore,
            homeTeamLogo,
            homeTeamName,
            awayTeamLogo,
            awayTeamName,
          };
        });
      setData(finalData);
    } catch (err) {
      setData(null);
      setError(err);
    }
  }, [date, status]);

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  return {
    data,
    error,
  };
};

export default useBoxScore;
