import { useLiveBoxScore } from "../../../hooks";
import { padLeft } from "../../../utils";
import DefaultHomeLogo from "../../../assets/images/team-logo.png";
import DefaultAwayLogo from "../../../assets/images/clippers-logo.png";

import "./homeLiveScoreWidget.scss";

const HomeLiveScoreWidget = () => {
  const { data } = useLiveBoxScore();
  const game = data?.[0];

  return (
    <div className="home-live-score">
      <div className="scores">
        <div className="grid-item logo">
          <img src={game?.awayTeamLogo || DefaultAwayLogo} alt="away-logo" />
          <div>{game?.awayTeamScore || 97}</div>
        </div>
        <div className="grid-item logo">
          <img
            src={
              game?.homeTeamLogo ||
              "https://upload.wikimedia.org/wikipedia/en/d/dc/Phoenix_Suns_logo.svg"
            }
            alt="home-logo"
          />
          <div>{game?.homeTeamScore || 97}</div>
        </div>
      </div>
      <div className="time-container">
        <div className="quarter">
          {game?.gameStatus
            ? !isNaN(game?.gameStatus)
              ? `Q${game?.gameStatus}`
              : game?.gameStatus.toUpperCase()
            : "Q4"}
        </div>
        <div className="remaining-time">
          <div>
            {padLeft(game?.remainingMinutess, game?.remainingSecondss) ||
              "02:35"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeLiveScoreWidget;
