import { useContext } from "react";
import { NavList } from "../../consts";
import { TabContext } from "../../store/context/tab";
import LiveLike from "../livelike/livelike";
import HomeLiveScoreWidget from "../widgets/homeLiveScoreWidget/homeLiveScoreWidget";
import "./headerVideo.scss";

const HeaderVideo = () => {
  const { tab } = useContext(TabContext);
  const renderHomeScore = tab === NavList.NBA;
  return (
    <div className={"header-video" + (!renderHomeScore && "--lone-widget")}>
      {renderHomeScore && <HomeLiveScoreWidget />}
      <LiveLike program={tab} />
    </div>
  );
};

export default HeaderVideo;
