import "./App.scss";
import NavBar from "./components/navbar/navbar";
import ScreenContainer from "./components/screenContainer/screenContainer";
import GlobalContext from "./store/context";

function App() {
  return (
    <div className="app-background">
      <GlobalContext>
        <NavBar />
        <ScreenContainer />
      </GlobalContext>
    </div>
  );
}

export default App;
