import { useContext, useEffect, useState } from "react";
import { NavList } from "../../consts";
import { TabContext } from "../../store/context/tab";
import NbaScreen from "../screens/nbaScreen/nbaScreen";
import MlbScreen from "../screens/mlbScreen/mlbScreen";

const ScreenContainer = () => {
  const { tab, setTab } = useContext(TabContext);
  const [displayScreen, setDisplayScreen] = useState(<NbaScreen />);

  useEffect(() => {
    if (tab === NavList.MLB) {
      setDisplayScreen(<MlbScreen />);
      return;
    }

    // This line is temporary, since NFL
    // and Settings are not contemplated yet
    setTab(NavList.NBA);
    setDisplayScreen(<NbaScreen />);
  }, [tab, setTab]);

  return displayScreen;
};

export default ScreenContainer;
