import "./leaderboard.scss";
const LeaderBoard = ({ entries }) => {
  return (
    <div className="leaderboard">
      <div className="header">
        <div>Rank</div>
        <div>Username</div>
        <div>Pts</div>
      </div>
      {entries?.map((entry, index) => {
        return (
          <div className={`entry ${(index + 1) % 2 === 0 ? "even" : ""}`}>
            <div>{entry.rank}</div>
            <div>{entry.profile_nickname}</div>
            <div>{entry.score}</div>
          </div>
        );
      })}
    </div>
  );
};
export default LeaderBoard;
