import { useContext, useState } from "react";
import { THEME } from "../../../../../consts";
import { SportContext } from "../../../../../store/context/sport";
import { SportsRadarWidget } from "../../../../widgets";
import "./stats.scss";

const widgets = ["StatsGraphic", "GamesHistogram", "Roster"];

const Stats = () => {
  const { sportInfo } = useContext(SportContext);
  const [params, setParams] = useState({
    theme: THEME,
    teamUid: sportInfo?.homeTeamUid,
    seasonId: sportInfo?.seasonId,
  });

  function onTeamSelected(_, obj) {
    setParams({
      theme: THEME,
      teamUid: obj.uniqueTeamId,
      seasonId: obj.seasonId,
    });
  }

  return (
    <div className="mlb-stats--container">
      <div className="mlb-stats--widget">
        <SportsRadarWidget
          id="mlb-team-selector"
          widget="us.common.teamSelector"
          params={{
            theme: THEME,
            seasonId: params?.seasonId,
            onItemClick: onTeamSelected,
          }}
        />
      </div>
      {widgets.map((name) => (
        <div key={name} className="mlb-stats--widget">
          <SportsRadarWidget
            id={"mlb-team-" + name}
            widget={"us.season.mlb.team" + name}
            params={params}
          />
        </div>
      ))}
    </div>
  );
};

export default Stats;
