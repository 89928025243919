import { mobileStatus } from "../../consts";
const Tab = ({ label, activeTab, onClick }) => {
  return (
    <li
      className={`tab-item ${activeTab === label ? "active" : ""}`}
      onClick={() => onClick(label)}
    >
      <span>{mobileStatus[label]}</span>
    </li>
  );
};

const MobileTabs = ({ activeTab, setActiveTab }) => {
  const onClickTab = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className={`schedule-widget-app `}>
      <ul className={`schedule-list`}>
        {Object.keys(mobileStatus).map((child) => {
          return (
            <Tab
              key={child}
              label={child}
              activeTab={activeTab}
              onClick={onClickTab}
            />
          );
        })}
      </ul>
    </div>
  );
};
export default MobileTabs;
