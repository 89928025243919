import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-react";
import { useEffect, useState } from "react";
import { AgoraVideoPlayer } from "agora-rtc-react";
import { UseResizeListener } from "../../hooks";
import Controls from "../controls/controls";
import Slider from "../slider/slider";
import "./videoCall.scss";
import {
  agoraConfig,
  appId,
  channelName,
  token,
  ClientEvents,
  MediaTypes,
} from "../../consts";

const useClient = createClient(agoraConfig);
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();

const VideoCall = () => {
  const { isMobile } = UseResizeListener();
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  useEffect(() => {
    let init = async (name) => {
      client.on(ClientEvents.USER_PUBLISHED, async (user, mediaType) => {
        await client.subscribe(user, mediaType);

        if (mediaType === MediaTypes.VIDEO) {
          setUsers((prevUsers) => {
            return [...prevUsers, user];
          });
        }

        if (mediaType === MediaTypes.AUDIO) {
          user.audioTrack?.play();
        }
      });

      client.on(ClientEvents.USER_UNPUBLISHED, (user, type) => {
        if (type === MediaTypes.AUDIO) {
          user.audioTrack?.stop();
        }

        if (type === MediaTypes.VIDEO) {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on(ClientEvents.USER_LEFT, (user) => {
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      await client.join(appId, name, token, null);

      if (tracks) {
        await client.publish([tracks[0], tracks[1]]);
      }

      setStart(true);
    };

    if (ready && tracks) {
      init(channelName);

      // only when tracks are non-null can we return
      // a cleanup function for closing the video call
      const leaveChannel = async () => {
        try {
          await client.leave();
          client.removeAllListeners();

          // we close the tracks to perform cleanup
          tracks[0]?.close(); // microphone
          tracks[1]?.close(); // camera
          setStart(false);
        } catch (err) {
          console.error("Error closing video call\n\n", err);
        }
      };

      return leaveChannel;
    }
  }, [client, ready, tracks]);

  return (
    <>
      {start && tracks && (
        <div className="videos-list">
          <Slider isUsersAboveSix={users?.length > 5} isMobile={isMobile}>
            <div>
              <div className="videos mine">
                <AgoraVideoPlayer
                  className="videosDiv"
                  videoTrack={tracks[1]}
                />
                <div className="bottom">
                  {ready && tracks && (
                    <Controls
                      client={client}
                      tracks={tracks}
                      setStart={setStart}
                    />
                  )}
                </div>
              </div>
            </div>
            {users.length > 0 &&
              users.map((user) => {
                if (user.videoTrack) {
                  return (
                    <div>
                      <div className="videos">
                        <AgoraVideoPlayer
                          className="videosDiv"
                          videoTrack={user.videoTrack}
                        />
                      </div>
                    </div>
                  );
                } else return null;
              })}
          </Slider>
        </div>
      )}
    </>
  );
};
export default VideoCall;
